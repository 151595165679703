import { applyMiddleware, createStore } from 'redux';
import { createLogger } from 'redux-logger';
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';
import reducers from './reducers';
import reduxThunk from 'redux-thunk';

import { routerMiddleware } from 'react-router-redux';
import * as historyObj from 'history';

export const history = historyObj.createBrowserHistory();

// Build the middleware for intercepting and dispatching navigation actions
const myRouterMiddleware = routerMiddleware(history);

const getMiddleware = () => {
  if (process.env.NODE_ENV === 'production') {
    return applyMiddleware(myRouterMiddleware, reduxThunk);
  } else {
    // Enable additional logging in non-production environments.
    return applyMiddleware(myRouterMiddleware, reduxThunk, createLogger());
  }
};

export const store = createStore(
  reducers, composeWithDevTools(getMiddleware()));
