import {
  APP_LOAD,
  REDIRECT,
  LOGOUT,
  LOGIN,
  LOGIN_BEGIN,
  CHANGE_PASSWORD,
  CHANGE_PASSWORD_BEGIN,
  CHANGE_LOGGED_IN_USER
} from '../actions/types';
import { CommonState, UserEntity } from './states';
import { LoadAppAction, LogoutAction } from '../actions/types';
import { LoginResponse } from '../entities/api/login-response';

const defaultState: CommonState = {
  appName: 'Portal',
  appLoaded: false,
  redirectTo: null,
  loggingResponse: LoginResponse.NONE,
  loggedInUser: {
    username: '',
    isTest: false,
    token: '',
  } as UserEntity,
  isLoggingIn: false,
  passwordChanged: false,
  isPasswordChanging: false,
};

export default (state: CommonState = defaultState, action: any): CommonState => {
  switch (action.type) {
    case APP_LOAD:
      return {
        ...state,
        loggingResponse: (action as LoadAppAction).payload ? LoginResponse.SUCCESS : LoginResponse.NONE,
        appLoaded: true,
        redirectTo: (action as LoadAppAction).payload ? null : '/login',
      };
    case REDIRECT:
      return { ...state, redirectTo: null };
    case LOGOUT:
      let logoutAction = action as LogoutAction;
      return { ...state, loggedInUser: defaultState.loggedInUser, redirectTo: logoutAction.payload ? '/login' : null, loggingResponse: LoginResponse.NONE };
    case LOGIN_BEGIN:
        return {
          ...state,
          isLoggingIn: true,
        };
    case LOGIN:
      return {
        ...state,
        redirectTo: (action.payload as LoginResponse) === LoginResponse.SUCCESS ? '/' : null,
        loggingResponse: (action.payload as LoginResponse),
        isLoggingIn: false,
      };
    case CHANGE_PASSWORD_BEGIN:
        return {
          ...state,
          isPasswordChanging: true,
        };
    case CHANGE_PASSWORD:
      return {
        ...state,
        redirectTo: action.payload ? '/' : null,
        passwordChanged: action.payload,
        isPasswordChanging: false,
      };
    case CHANGE_LOGGED_IN_USER:
      return {
        ...state,
        loggedInUser: action.payload as UserEntity,
      }
    default:
      return state;
  }
};
