import { PracticalTip } from '../entities/local/tips';
import { FilterState, UserEntity } from '../reducers/states';
import { Enumerator, Enumerators, GenericPageType } from '../entities/local/enumerator';
import { LoginResponse } from '../entities/api/login-response';

export const FETCH_ENUMS = 'FETCH_ENUMS';
export const REFRESH_TIPS = 'REFRESH_TIPS';
export const FETCH_TIPS_START = 'FETCH_TIPS_START';
export const FETCH_GENERIC_PAGE = 'FETCH_GENERIC_PAGE';
export const FETCH_TIPS = 'FETCH_TIPS';
export const FETCH_TIP_DETAIL = 'FETCH_TIP_DETAIL';
export const SEND_GLOBAL_FEEDBACK = 'SEND_GLOBAL_FEEDBACK';
export const SEND_TIP_FEEDBACK = 'SEND_TIP_FEEDBACK';
export const SEND_ANALYTICS = 'SEND_ANALYTICS';
export const APP_LOAD = 'APP_LOAD';
export const CHANGE_LOGGED_IN_USER = 'CHANGE_LOGGED_IN_USER';
export const REDIRECT = 'REDIRECT';
export const SET_SCROLLING_OFFSET = 'SET_SCROLLING_OFFSET';
export const LOGOUT = 'LOGOUT';
export const LOGIN = 'LOGIN';
export const LOGIN_BEGIN = 'LOGIN_BEGIN';
export const REGISTER = 'REGISTER';
export const APPLY_FILTER = 'APPLY_FILTER';
export const CHANGE_PASSWORD_BEGIN = 'CHANGE_PASSWORD_BEGIN';
export const CHANGE_PASSWORD = 'CHANGE_PASSWORD';

export interface PortalAction<T> {
  type: string;
  payload: T;
  error?: string;
}

export interface LoadAppAction extends PortalAction<boolean> { }

export const loadAppAction = (isLoggedIn: boolean): LoadAppAction => {
  return {
    type: APP_LOAD,
    payload: isLoggedIn
  };
};

export interface SetOffsetAction extends PortalAction<number> { }

export const setScrollingOffsetAction = (val: number): SetOffsetAction => {
  return {
    type: SET_SCROLLING_OFFSET,
    payload: val
  }
}

export interface RedirectAction extends PortalAction<boolean> { }

export const redirectAction = (): RedirectAction => {
  return {
    type: REDIRECT,
    payload: true
  };
};

export interface LogoutAction extends PortalAction<boolean> { }

export const logoutAction = (redirect: boolean): LogoutAction => {
  return {
    type: LOGOUT,
    payload: redirect
  };
};

export interface LoginAction extends PortalAction<LoginResponse> { }

export const loginAction = (payload: LoginResponse): LoginAction => {
  return {
    type: LOGIN,
    payload
  };
};

export interface ChangePasswordAction extends PortalAction<boolean> { }

export const changePasswordAction = (payload: boolean): ChangePasswordAction => {
  return {
    type: CHANGE_PASSWORD,
    payload
  };
};


export interface FetchEnumsAction extends PortalAction<Enumerators> {}

export const fetchEnumsAction = (payload: Enumerators): FetchEnumsAction => {
  return {
    type: FETCH_ENUMS,
    payload
  };
};

export interface RefreshTipsAction extends PortalAction<boolean> {}

export const refreshTipsAction = (payload: boolean): RefreshTipsAction => {
  return {
    type: REFRESH_TIPS,
    payload
  };
};

export interface ChangeLoggedInUserAction extends PortalAction<UserEntity> {}

export const changeLoggedInUserAction = (payload: UserEntity): ChangeLoggedInUserAction => {
  return {
    type: CHANGE_LOGGED_IN_USER,
    payload
  };
};

export interface LoadGenericPageAction extends PortalAction<[GenericPageType, string]> {}

export const loadGenericPageAction = (payload: [GenericPageType, string]): LoadGenericPageAction => {
  return {
    type: FETCH_GENERIC_PAGE,
    payload
  }
}

export interface FetchTipsStartAction extends PortalAction<boolean> {}

export const fetchTipsStartAction = (payload: boolean): FetchTipsStartAction => {
  return {
    type: FETCH_TIPS_START,
    payload
  };
};

export interface FetchTipsAction extends PortalAction<PracticalTip[]> {}

export const fetchTipsAction = (payload: PracticalTip[]): FetchTipsAction => {
  return {
    type: FETCH_TIPS,
    payload
  };
};

export interface FetchTipDetailAction extends PortalAction<PracticalTip> {}

export const fetchTipDetailAction = (payload: PracticalTip): FetchTipDetailAction => {
  return {
    type: FETCH_TIP_DETAIL,
    payload
  };
};

export interface SendGlobalFeedbackAction extends PortalAction<boolean> { }

export const sendGlobalFeedbackAction = (payload: boolean): SendGlobalFeedbackAction => {
  return {
    type: SEND_GLOBAL_FEEDBACK,
    payload
  };
};

export interface SendTipFeedbackAction extends PortalAction<boolean> { }

export const sendTipFeedbackAction = (payload: boolean): SendTipFeedbackAction => {
  return {
    type: SEND_TIP_FEEDBACK,
    payload
  };
};

export interface SendAnalyticsAction extends PortalAction<boolean> { }

export const sendAnalyticsAction = (payload: boolean): SendAnalyticsAction => {
  return {
    type: SEND_ANALYTICS,
    payload
  };
};

export interface ApplyFilterAction extends PortalAction<FilterState> { }

export const applyFilterAction = (payload: FilterState): ApplyFilterAction => {
  return {
    type: APPLY_FILTER,
    payload
  };
};