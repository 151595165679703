import React, { ChangeEventHandler, ChangeEvent } from 'react';
import './header.css';
import Modal from 'react-modal';
import { connect } from 'react-redux';
import Feedback from './feedback';
import Filters from './filters';
import { Link } from 'react-router-dom';
import { PortalState, FilterState, UserEntity } from '../reducers/states';
import logoIcon from '../images/clara.png';
import { logOut, refreshTips } from '../actions/index';
import { splitMultilineTooltip } from '../utils';
import HelpIcon from '@material-ui/icons/Help';
import ReactTooltip from 'react-tooltip';
import { Enumerators } from '../entities/local/enumerator';

interface HeaderStateProps {
  isOpen: boolean;
  menuOverlay: boolean;
}

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)'
  }
};

interface HeaderProps {
  filters: FilterState;
  enumerators: Enumerators;
  user: UserEntity;
}
interface HeaderDispatchProps {
  logOut(redirect: boolean): any;
  refreshTips(): any;
}

export class Header extends React.Component<HeaderProps & HeaderDispatchProps, HeaderStateProps> {

  constructor(props: any) {
    super(props);
    this.state = {
      isOpen: false,
      menuOverlay: false,
    };
  }

  logout = () => {
    this.props.logOut(true);
  }

  openModal = () => {
    this.setIsOpen(true);
  }

  closeModal = () => {
    this.setIsOpen(false);
  }

  setIsOpen(isOpen: boolean) {
    this.setState(
      {
        ...this.state,
        isOpen: isOpen,
      }
    );
  }

  refreshTips() {
    this.props.refreshTips();
  }

  switchOverlay(menuOverlay: boolean) {
    this.setState(
      {
        ...this.state,
        menuOverlay: menuOverlay,
      }
    );
  }

  render() {
    const testDisclaimer = this.props.enumerators.labels && this.props.enumerators.labels.find(lab => lab.name === 'test_disclaimer');
    return (
      <div>
        <Modal
          isOpen={this.state.isOpen}
          contentLabel='Feedback'
          style={customStyles}
        >
          <button className='float-right' onClick={this.closeModal}>X</button>
          <div className='clearfix'></div>
          <Feedback onSubmit={() => this.setIsOpen(false)}  />
          }
        </Modal>
        {this.state.menuOverlay &&
          <nav className='lg:hidden block fixed bg-white w-full h-full left-0 top-0 overflow-y-hidden z-50'>
            <div className='mt-4 flex flex-row justify-between mx-4'>
                <div className='flex-col'>
                  <Link to={{pathname: `/`}} className='text-2xl block' onClick={() => { this.switchOverlay(false); this.refreshTips(); } }>
                    Návody
                  </Link>
                  <Link to={{pathname: `/tools`}} className='text-2xl block' onClick={() => { this.switchOverlay(false);  this.refreshTips(); } }>
                    Produkty
                  </Link>
                  <Link to={{pathname: '/how_to_begin'}} className='text-2xl block'>
                    Jak začít?
                  </Link>
                  <Link to={{pathname:`/change_password`}} className='text-2xl'>
                    Změna hesla
                  </Link>
                  <div className='text-2xl'>
                    <button onClick={() => this.logout()} >Odhlásit se</button>
                  </div>
                </div>
                <div className='flex-row'>
                  <button className='items-center button-small text-black border-black hover:text-gray-500 hover:border-gray-500' onClick={() => this.switchOverlay(false)}>
                    X
                  </button>
                </div>
              </div>
          </nav>
        }
        <nav className='mt-20 container'>
        {this.props.user && this.props.user.isTest && testDisclaimer && <div className='text-center test-disclaimer mb-2'><h2>{testDisclaimer.description}</h2></div>}
          <div className='flex justify-between'>
            <Link to={{pathname: `/`}} className='flex mb-2' onClick={() => { this.refreshTips(); } }>
              <div className='flex-shrink '>
                <img className='logoicon' src={logoIcon}></img>
              </div>
              <div className='flex-shrink mx-2 mt-2'>
                <div className='flex-1 text-4xl font-medium'>
                  <span>Clara</span>
                </div>
              </div>
            </Link>
            <div className='mt-6 lg:flex-row flex-col hidden lg:flex'>
              <Link to={{pathname: `/tips`}} className='mx-2 text-lg' onClick={() => { this.refreshTips(); } }>
                Návody
              </Link>
              <Link to={{pathname: `/tools`}} className='mx-2 text-lg' onClick={() => { this.refreshTips(); } }>
                Produkty
              </Link>
              <Link to={{pathname: '/how_to_begin'}} className='mx-2 text-lg'>
                Jak začít?
              </Link>
              <Link to={{pathname:`/change_password`}} className='mx-2 text-lg'>
                Změna hesla
              </Link>
              <div className='mx-2 text-lg'>
                <button onClick={() => this.logout()} >Odhlásit se</button>
              </div>
            </div>
            <div className='block lg:hidden'>
              <button className='flex items-center px-4 py-3 button-small text-black border-black hover:text-gray-500 hover:border-gray-500' onClick={() => this.switchOverlay(true)}>
                <svg className='fill-current h-3 w-3' viewBox='0 0 20 20'><title>Menu</title><path d='M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z'/></svg>
              </button>
            </div>
          </div>
        </nav>
      </div>
    );
  }
}

const mapStateToProps = (state: PortalState): HeaderProps => {
  console.log('USER: ', state.common.loggedInUser);
  return {
    filters: state.filters,
    enumerators: state.airtable.enums,
    user: state.common.loggedInUser,
  };
};

const dispatchProps = {
  logOut,
  refreshTips,
};

export default connect(mapStateToProps, dispatchProps)(Header);
