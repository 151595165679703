import airtable from './airtable-reducer';
import { combineReducers, Reducer } from 'redux';
import { routerReducer, RouterState } from 'react-router-redux';
import common from './common';
import genericPages from './generic-pages';
import filters from './filters';


export default combineReducers({
  airtable,
  common,
  filters,
  genericPages,
  router: routerReducer as Reducer<RouterState>
});
