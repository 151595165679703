import { APPLY_FILTER, ApplyFilterAction, REFRESH_TIPS } from '../actions/types';
import { FilterState } from './states';


const defaultState: FilterState = {
  filters: new Map(),
  fullText: ''
};

export default (state = defaultState, action: any): FilterState => {
  let typedAction = action as ApplyFilterAction;

  switch (typedAction.type) {
    case REFRESH_TIPS:
      return  {
        filters: new Map(),
        fullText: ''
      };
    case APPLY_FILTER:
      return {
        ...state,
        ...typedAction.payload
      };
    default:
      return state;
  }
};
