import { EnumeratorsResponse, EnumeratorRecord, LabelRecord } from '../entities/api/enumerators';
import { Enumerator, Enumerators, Label } from '../entities/local/enumerator';


export const enumeratorMapper = (rec: EnumeratorRecord): Enumerator => {
  return {
    name: rec.fields.NAME,
    nameCz: rec.fields.NAME_LOCALE,
    valuesCz: rec.fields.VALUES_LOCALE.split('$').filter(val => val.trim().length !== 0),
    values: rec.fields.VALUES.split('$').filter(val => val.trim().length !== 0)
  };
};

export const labelMapper = (rec: LabelRecord): Label => {
  return {
    name: rec.fields.nazev,
    description: rec.fields.text
  };
};


export const enumeratorsMapper = (response: EnumeratorsResponse): Enumerators => {
  return {
    enums: response.enumerators.map(enumeratorMapper),
    labels: response.labels.map(labelMapper),
  };
};