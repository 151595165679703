import React from 'react';
import { connect } from 'react-redux';
import { fetchTipDetail } from '../actions';
import { PracticalTip } from '../entities/local/tips';
import { RouteComponentProps } from 'react-router-dom';
import Feedback from './feedback';
import Modal from 'react-modal';
import { PortalState } from '../reducers/states';
import logoIcon from '../images/clara.png';
import { analyticsDetail } from '../actions/index';
import { Enumerator, TipTypes, Label } from '../entities/local/enumerator';
import ReactHtmlParser, { processNodes, convertNodeToElement } from 'react-html-parser';
import { dateFormatter, getEnumValue, splitMultilineTooltip } from '../utils';
import AccessAlarmIcon from '@material-ui/icons/AccessAlarm';
import AttachMoney from '@material-ui/icons/AttachMoney';
import OfflineBolt from '@material-ui/icons/OfflineBolt';
import LibraryAddCheck from '@material-ui/icons/LibraryAddCheck';
import Group from '@material-ui/icons/Group';
import Loop from '@material-ui/icons/Loop';
import Snooze from '@material-ui/icons/Snooze';
import Business from '@material-ui/icons/Business';
import HelpIcon from '@material-ui/icons/Help';
import ReactTooltip from 'react-tooltip';
import { Link } from 'react-router-dom';


const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)'
  }
};

interface TipDetailDispatchProps {
  fetchTipDetail(id: string, state: any): any;
  analyticsDetail(name: string): any;
}

interface TipDetailStateProps {
  id: string;
  tip?: PracticalTip;
  alltips: PracticalTip[];
  enumerators: Enumerator[];
  labels: Label[];
}

export interface TipDetailOwnProps {
  type: TipTypes;
}


interface RouterProps extends RouteComponentProps<{
  id: string
}> { }

class TipDetail extends React.Component<TipDetailStateProps & TipDetailDispatchProps & RouterProps & TipDetailOwnProps> {
  private firstRun = false;

  constructor(props: any) {
    super(props);
    this.state = {
    };
  }


  componentDidMount() {
    this.props.fetchTipDetail(this.props.id, this.props.alltips);
  }

  goBack() {
    this.props.history.goBack();
   /* if(this.props.tip!!.tipType === TipTypes.TOOLS) {
      this.props.history.push('/tools');
    } else {
      this.props.history.push('/tips');
    }*/
  }


  render() {
    if (this.props.tip && this.props.tip.id === this.props.id) {
      if (!this.firstRun) {
        this.firstRun = true;
        this.props.analyticsDetail(this.props.tip.name);
      }
      const tip = this.props.tip;
      return (
        <div className='mt-3'>
          <div className='relative container'>
            <div className='-mx-4'>
              <div className='tipDetailImage'>
                <img src={tip.pictures[0].thumbnails.large.url} ></img>
                <div className='tilDetailTitle'>
                  <div className='m-8 relative'>
                    <h2 className='text-4xl leading-tight'>{tip.name}</h2>
                    <div className='flex flex-row flex-wrap tags'>
                      {[...(tip.enums.get('tagy') as any)].map(tag => {
                        return <div key={tag} className='tag'>{getEnumValue(this.props.enumerators, 'tagy', tag)}</div>;
                      })}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='container'>
            <div className='flex lg:flex-row flex-col justify-between py-8 -mx-4'>
              <div className='flex-2 m-4'>
                <div className='htmlcontent'>{ReactHtmlParser(tip.description)}</div>
                <div>Tip od: {tip.source}</div>
              </div>
              <div className='flex-1 m-4'>
                <div className='mt-4 font-semibold'>Hlavní přínosy</div>
                <div>
                  <ul>
                    {tip.benefits.split(',').map(benefit => {
                      return <li key={benefit}>{benefit}</li>;
                    })}
                  </ul>
                </div>
                <div className='mt-4 font-semibold'>Podporuje happiness principy:</div>
                {this.renderSupport()}
               <div className='mt-4'>
                <Link to={{pathname: '/what_are_principles'}} className='underline yellow text-sm'>Co jsou happiness principy?</Link>
               </div>
              </div>
            </div>
          </div>
          {this.renderEnums()}
        </div>
      );
    } else {
      return <div className='spinner'></div>;
    }
  }

  renderSupport() {
    const tip = this.props.tip as PracticalTip;
    let supportData = tip.enums.get('podporuje') as Set<string>;
    let supportArray = this.props.enumerators.filter(s => s.name === 'podporuje')[0];
    return (
      <div className='flex flex-col mt-1'>
        <div className='flex flex-row text-md'>
          {supportArray.values.length > 0 && <div className={'support mr-5 ' + (supportData.has(supportArray.values[0]) ? 'highlight' : '')}><span>{getEnumValue(this.props.enumerators, 'podporuje', supportArray.values[0])}</span></div>}
          {supportArray.values.length > 1 && <div className={'support mr-5 ' + (supportData.has(supportArray.values[1]) ? 'highlight' : '')}><span>{getEnumValue(this.props.enumerators, 'podporuje', supportArray.values[1])}</span></div>}
          {supportArray.values.length > 2 && <div className={'support mr-5 ' + (supportData.has(supportArray.values[2]) ? 'highlight' : '')}><span>{getEnumValue(this.props.enumerators, 'podporuje', supportArray.values[2])}</span></div>}
          {supportArray.values.length > 3 && <div className={'support mr-5 ' + (supportData.has(supportArray.values[3]) ? 'highlight' : '')}><span>{getEnumValue(this.props.enumerators, 'podporuje', supportArray.values[3])}</span></div>}
        </div>
      </div>);
  }


  renderEnums() {
    const enumOrders = [['prostredi', 'velikost_skupiny', 'financni_narocnost'], ['cas_realizace', 'cas_priprava', 'frekvence'], ['employee_lifecycle', 'typicky_resi']];

    return(
      <div className='bg-gray-100'>
        <div className='container'>
          <div className='flex flex-wrap my-4'>
            {enumOrders.map((val) => {
              return (
                <div className='flex-col w-full sm:w-1/2 lg:w-1/3'>
                  {val.map((enumVal) => {
                    const enumObj = this.props.enumerators.find(en => en.name === enumVal);
                    if(enumObj) {
                      return this.renderEnum(enumObj);
                    }
                    return null;
                  })}
                </div>
              )
            })}
          </div>
        </div>
      </div>
    );
  }

  renderEnum(val: Enumerator) {
    const tip = this.props.tip as PracticalTip;
    const hint = this.props.labels.find(l => l.name === val.name);
    return (
      <div key={`hint${val.name}`} className='py-2 flex flex-row'>
        <div className='mx-2'>
          {this.renderEnumIcon(val.name)}
        </div>
        <div>
          <strong>{val.nameCz}</strong>
          <div key={val.name}>{[...(tip.enums.has(val.name) ? tip.enums.get(val.name) as any : [])].map(techVal => val.valuesCz[val.values.indexOf(techVal)]).map((textval, index) => <div key={`${index}${val.name}`}>{textval}</div>)}</div>
        </div>
        {hint &&
          <div className='help' data-tip={splitMultilineTooltip(hint.description)}>
            <HelpIcon/>
            <ReactTooltip multiline={true} />
          </div>
        }
      </div>
    );
  }

  renderEnumIcon(key: string) {
    switch(key) {
      case 'financni_narocnost':
        return <AttachMoney />;
      case 'cas_priprava':
        return <AccessAlarmIcon />;
      case 'cas_realizace':
        return <AccessAlarmIcon />;
      case 'podporuje':
        return <OfflineBolt />;
      case 'typicky_resi':
        return <LibraryAddCheck />;
      case 'velikost_skupiny':
        return <Group />;
      case 'prostredi':
        return <Business />;
      case 'employee_lifecycle':
        return <Loop />;
      case 'frekvence':
        return <Snooze />;
        default:
          return <AccessAlarmIcon />;
    }
  }
}

const mapStateToProps = (state: PortalState, ownProps: RouterProps & TipDetailOwnProps): TipDetailStateProps => {
  return {
    id: ownProps.match.params.id,
    tip: state.airtable.tipDetail,
    alltips: state.airtable.tips,
    enumerators: state.airtable.enums.enums,
    labels: state.airtable.enums.labels,
  };
};

const dispatchProps = {
  fetchTipDetail,
  analyticsDetail
};

export default connect(mapStateToProps, dispatchProps)(TipDetail);
