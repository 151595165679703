import React from 'react';
import { connect } from 'react-redux';
import { fetchTips } from '../actions';
import { PracticalTip } from '../entities/local/tips';
import { Link } from 'react-router-dom';
import { FilterState } from '../reducers/states';
import { PortalState } from '../reducers/states';
import searchIcon from '../images/zoom.png';
import { applyFilter, analyticsFulltext, setScrollingOffset } from '../actions/index';
import Select, { Theme, ActionMeta, ValueType, OptionsType } from 'react-select';
import { dateFormatter, getEnumValue, getFilteredValue, splitMultilineTooltip } from '../utils';
import { Enumerator, TipTypes, Enumerators } from '../entities/local/enumerator';
import HelpIcon from '@material-ui/icons/Help';
import ReactTooltip from 'react-tooltip';

interface TipsListDispatchProps {
  fetchTips(type: TipTypes, enumerators: Enumerators): any;
  applyFilter(newFilter: FilterState, refreshLocation: boolean): any;
  analyticsFulltext(val: string): any;
  setScrollingOffset(val: number): any;
}
interface TipsListStateProps {
  tips: PracticalTip[];
  filters: FilterState;
  enumerators: Enumerators;
  refreshRequired: boolean;
  refreshingTips: boolean;
  tipScrollingOffset: number;
}

interface TipsState {
  fullText: string;
}

const dropdownTheme = (theme: Theme) => ({
  ...theme,
  borderRadius: 0,
  colors: {
    ...theme.colors,
    primary: 'black',
  },
  spacing: {
    ...theme.spacing,
  }
});


export interface TipsListOwnProps {
  type: TipTypes;
}

class TipsList extends React.Component<TipsListStateProps & TipsListDispatchProps & TipsListOwnProps, TipsState> {

  currentFulltextTimeout: number = -1;

  constructor(props: any) {
    super(props);
    this.state = {
      fullText: props.filters.fullText,
    };
  }

  componentWillReceiveProps(nextProps: TipsListStateProps & TipsListDispatchProps & TipsListOwnProps) {
    if (nextProps.refreshRequired) {
      this.props.fetchTips(nextProps.type, nextProps.enumerators);
    }
  }


  componentDidMount() {
    if(this.props.tips.length === 0) {
      this.props.fetchTips(this.props.type, this.props.enumerators);
    }
  }

  handleLoadMore = (evt: any) => {
    evt.preventDefault();
    this.props.setScrollingOffset(this.props.tipScrollingOffset+1);
  }

  handleLoadAll = (evt: any, maxLength: number) => {
    evt.preventDefault();
    this.props.setScrollingOffset(Math.floor(maxLength / 9));
  }

  handleTagModify = (evt: any, tagName: string, add: boolean) => {
    evt.preventDefault();
    if(!this.props.filters.filters.has('tagy')) {
      this.props.filters.filters.set('tagy', new Set());
    }
    let existing = this.props.filters.filters.get('tagy')!!;
    if(add) {
      existing.add(tagName);
    } else {
      existing.delete(tagName);
    }

    this.props.filters.filters.set('tagy', existing);
    this.props.applyFilter(this.props.filters, true);
  }


  isFilteredOut<T>(filter: Set<T>, collection: Set<T>) {
    if (filter.size === 0 || !collection) {
      return false;
    }
    let filteredOut = true;
    filter.forEach(val => {
      if (collection.has(val)) {
        filteredOut = false;
        return;
      }
    });
    return filteredOut;
  }

  isValidTip(tip: PracticalTip) {
    let filters = this.props.filters;
    for (let key of filters.filters.keys() as any) {
      if (this.isFilteredOut(filters.filters.get(key)!!, tip.enums.get(key)!!)) {
        return false;
      }
    }

    if (filters.fullText) {
      let lowerCase1 = filters.fullText.toLocaleLowerCase();
      let lowerCase2 = tip.name.toLocaleLowerCase() + tip.description.toLocaleLowerCase();
      // check tags as well
      const tags = tip.enums.get('tagy');
      if(tags && tags.has(lowerCase1)){
        return true;
      }

      // check benefits
      if(tip.benefits.toLowerCase().includes(lowerCase1)){
        return true;
      }

      let arr = lowerCase1.split(' ');
      let output = false;
      for (let i = 0; i < arr.length; i++) {
        if (lowerCase2.indexOf(arr[i]) !== -1) {
          output = true;
          break;
        }
      }
      if (!output) {
        return false;
      }
    }

    return true;
  }

  renderList(tips: PracticalTip[]) {
    const enums = this.props.enumerators.enums;
    if (this.props.tips && enums && enums.length !== 0) {
      return tips.slice(0, (this.props.tipScrollingOffset + 1) * 9).map(tip => {
        const tags = tip.enums.get('tagy');
        const linkUrl = this.props.type === TipTypes.PRACTICAL_TIPS ? '/tip' : '/tool';
        return (
          <Link className='flex-initial w-full lg:w-1/3 p-4 my-2' key={tip.id} to={{ pathname: `${linkUrl}/${tip.id}` }}>
            <div className='tipimage'>
              {tip.isNew && <div className='newtip'>NOVÉ</div>}
              <img src={tip.pictures[0].thumbnails.large.url} />
            </div>
            <div className='flex flex-row flex-wrap tags mt-2'>
              {tags && [...(tags as any)].map(tag => {
                const isAlreadySelected = this.props.filters.filters.has('tagy') && this.props.filters.filters.get('tagy')!!.has(tag);
                if(isAlreadySelected) {
                  return  <button key={tip.id + tag} onClick={(evt) => this.handleTagModify(evt, tag, false)} className='tag pb-1 selected'>{getEnumValue(enums, 'tagy', tag)}</button>;
                }
                return  <button key={tip.id + tag} onClick={(evt) => this.handleTagModify(evt, tag, true)} className='tag pb-1'>{getEnumValue(enums, 'tagy', tag)}</button>;
              })}
            </div>
            <h3 className='text-xl mt-2 font-medium'>{tip.name}</h3>
          </Link>
        );
      });
    } else {
      return null;
    }
  }

  basicTruncate(str: string, length: number = 30) {
    const truncStr = str.substring(0, length);
    return str.length > length ? `${truncStr}...` : str;
  }

  fulltextHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    let val = event.target.value;
    this.setState({
      ...this.state,
      fullText: val,
    });
    if (this.currentFulltextTimeout !== -1) {
      clearTimeout(this.currentFulltextTimeout);
    }
    this.currentFulltextTimeout = setTimeout(() => {
      this.props.filters.fullText = val;
      this.props.analyticsFulltext(val);
      this.props.applyFilter(this.props.filters, true);
    }, 200) as any;
  }

  handleDropdownChange = (value: any, action: ActionMeta) => {
    const filter = (value ? value.value : '') as string;
    const filterName = action.name as string;
    if(filter) {
      this.props.filters.filters.set(filterName, new Set([filter]));
    } else {
      this.props.filters.filters.delete(filterName);
    }
    this.props.applyFilter(this.props.filters, true);
  }

  renderFilters() {
    const enums = this.props.enumerators.enums;
    if(enums && enums.length !== 0) {
      const support = enums.filter(en => en.name === 'cas_priprava')[0];
      const supportOpt = support.values.map((val, idx) => ({ value: val, label: support.valuesCz[idx]}));
      const groupSize = enums.filter(en => en.name === 'velikost_skupiny')[0];
      const groupSizeOpt = groupSize.values.map((val, idx) => ({ value: val, label: groupSize.valuesCz[idx]}));
      const requirements = enums.filter(en => en.name === 'financni_narocnost')[0];
      const requirementsOpt = requirements.values.map((val, idx) => ({ value: val, label: requirements.valuesCz[idx]}));

      return(
        <div className='flex flex-col lg:flex-row justify-between flex-grow lg:-mx-4'>
          <div className='w-full lg:w-1/4 lg:mx-4 lg:my-0 my-2 relative'>
            <Select className='dropdown' value={getFilteredValue(this.props.filters, 'cas_priprava', supportOpt)}  name={support.name} theme={dropdownTheme} onChange={this.handleDropdownChange} placeholder={support.nameCz} isClearable={true} options={supportOpt} />
            <div className='dropHelp' data-tip={splitMultilineTooltip(this.props.enumerators.labels.find(lab => lab.name === 'cas_priprava')!!.description)}>
              <HelpIcon />
              <ReactTooltip multiline={true} />
            </div>
          </div>
          <div className='w-full lg:w-1/4 lg:mx-4 lg:my-0 my-2 relative'>
            <Select className='dropdown' value={getFilteredValue(this.props.filters, 'velikost_skupiny', groupSizeOpt)} name={groupSize.name} theme={dropdownTheme} onChange={this.handleDropdownChange} placeholder={groupSize.nameCz} isClearable={true}  options={groupSizeOpt} />
            <div className='dropHelp' data-tip={splitMultilineTooltip(this.props.enumerators.labels.find(lab => lab.name === 'velikost_skupiny')!!.description)}>
              <HelpIcon />
              <ReactTooltip multiline={true} />
            </div>
          </div>
          <div className='w-full lg:w-1/4 lg:mx-4 lg:my-0 my-2 relative'>
            <Select className='dropdown' value={getFilteredValue(this.props.filters, 'financni_narocnost', requirementsOpt)} name={requirements.name} theme={dropdownTheme} onChange={this.handleDropdownChange} placeholder={requirements.nameCz} isClearable={true}  options={requirementsOpt} />
            <div className='dropHelp' data-tip={splitMultilineTooltip(this.props.enumerators.labels.find(lab => lab.name === 'financni_narocnost')!!.description)}>
              <HelpIcon />
              <ReactTooltip multiline={true} />
            </div>
          </div>
          <div className='input-container w-full lg:w-1/4 lg:mx-4 lg:my-0 my-2'>
            <input className='px-4 outline-none' type='search' id='mySearch' name='q' placeholder='Vyhledat' value={this.state.fullText} onChange={this.fulltextHandler} />
            <img src={searchIcon} alt='vyhledat'></img>
          </div>
        </div>
      );
    } else {
      return null;
    }
  }

  renderTags() {
    const tags = this.props.enumerators.enums.filter(en => en.name === 'tagy');
    
    if(tags && tags.length > 0) {
      return tags[0].values.map(tag => {
        const isAlreadySelected = this.props.filters.filters.has('tagy') && this.props.filters.filters.get('tagy')!!.has(tag);
        if(isAlreadySelected) {
          return  <button key={tag} onClick={(evt) => this.handleTagModify(evt, tag, false)} className='tag pb-1 selected'>{getEnumValue(this.props.enumerators.enums, 'tagy', tag)}</button>;
        }
        return  <button key={tag} onClick={(evt) => this.handleTagModify(evt, tag, true)} className='tag pb-1'>{getEnumValue(this.props.enumerators.enums, 'tagy', tag)}</button>;
      });
    }

    return null;
  }

  calcResult(num: number) {
    if(num === 1) return 'výsledek';
    if(num <= 4) return 'výsledky';
    return 'výsledků';
  }

  render() {
    const filteredTips = this.props.tips.filter(tip => this.isValidTip(tip));
    const title = this.props.type === TipTypes.PRACTICAL_TIPS ? 'Návody' : 'Produkty';
    const moreButtonTitle = this.props.type === TipTypes.PRACTICAL_TIPS ? 'Dalších 9 tipů' : 'Dalších 9 nástrojů';
    const descKey = this.props.type === TipTypes.PRACTICAL_TIPS ? 'tipy' : 'tools';

    return (
      <div>
        <div className='container flex flex-col mt-10'>
          <div className='text-2xl mb-2'>{(filteredTips.length !== 0 && !this.props.refreshingTips) ? `${title} (${filteredTips.length} ${this.calcResult(filteredTips.length)})` : title + ` (Žádné výsledky)`}</div>
          {this.props.enumerators.labels && this.props.enumerators.labels.find(lab => lab.name === descKey) &&
            <p className='mb-4'>{this.props.enumerators.labels.find(lab => lab.name === descKey)!!.description}</p>
          }
          {this.renderFilters()}
        </div>
        <div className='container flex flex-row mt-5 -mx-2'>
          <div className='-mx-1'>
            {this.renderTags()}
          </div>
        </div>
        
        {(!this.props.tips || this.props.tips.length === 0 || this.props.refreshingTips) && <div className='spinner'></div>}
        <div className='mx-auto mt-4 container'>
          <div className='flex flex-wrap -mx-4'>
            {!this.props.refreshingTips && this.renderList(filteredTips)}
          </div>
        </div>
        {((this.props.tipScrollingOffset + 1) * 9) < filteredTips.length && !this.props.refreshingTips &&
          <div className='text-center'>
            <button className='button-mid mx-2 my-2' onClick={this.handleLoadMore}>{moreButtonTitle}</button>
            <button className='button-mid mx-2 my-2' onClick={(evt) => this.handleLoadAll(evt, filteredTips.length)}>Rozbalit vše</button>
          </div>
        }
      </div>
    );
  }
}

const mapStateToProps = (state: PortalState, ownProps: TipsListOwnProps): TipsListStateProps => {
  return {
    tips: state.airtable.tips,
    filters: state.filters,
    enumerators: state.airtable.enums,
    refreshRequired: state.airtable.refreshRequired,
    refreshingTips: state.airtable.refreshingTips,
    tipScrollingOffset: state.airtable.tipScrollingOffset,
  };
};

const dispatchProps = {
  fetchTips,
  applyFilter,
  analyticsFulltext,
  setScrollingOffset
};

export default connect(mapStateToProps, dispatchProps)(TipsList);
