import { TipsResponse, TipRecord } from '../entities/api/tips';
import { PracticalTip } from '../entities/local/tips';
import { Enumerator, TipTypes } from '../entities/local/enumerator';
import { isArray } from 'util';

export const tipMapper = (rec: TipRecord, enums: Enumerator[], tipType: TipTypes): PracticalTip => {

  let tipEnums: Map<string, Set<string>> = new Map();

  // map enumerators
  for(let en of enums) {
    if(Object.keys(rec.fields).indexOf(en.name) !== -1) {
      if(isArray((rec.fields as any)[en.name])) {
        let techValues = (rec.fields as any)[en.name] as string[];
        tipEnums.set(en.name, new Set(techValues));
      } else {
        let techValue = (rec.fields as any)[en.name] as string;
        tipEnums.set(en.name, new Set([techValue]));
      }
    }
  }

  return {
    id: rec.id,
    name: rec.fields.nazev,
    description: rec.fields.popis,
    isNew: rec.fields.novinky === 'new',
    benefits: rec.fields.hlavni_prinosy,
    source: rec.fields.zdroj,
    created: new Date(rec.fields['Datum vytvoření']),
    URL: rec.fields.URL,
    pictures: rec.fields.foto,
    enums: tipEnums,
    tipType: tipType
  };
};

export const tipsMapper = (response: TipsResponse, enums: Enumerator[], tipType: TipTypes): PracticalTip[] => {
  return response.records.map((rec) => tipMapper(rec, enums, tipType));
};