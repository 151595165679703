import React from 'react';
import { connect } from 'react-redux';
import { fetchPage } from '../actions';
import { PortalState } from '../reducers/states';
import ReactHtmlParser from 'react-html-parser';
import { GenericPageType } from '../entities/local/enumerator';

interface GenericPageDispatchProps {
  fetchPage(type: GenericPageType): any;
}

interface GenericPageStateProps {
  genericPages: {
    [key: string]: string
  };
}


export interface GenericPageOwnProps {
  type: GenericPageType;
}

class GenericPage extends React.Component<GenericPageStateProps & GenericPageDispatchProps & GenericPageOwnProps> {

  componentDidMount() {
    if(!this.props.genericPages[this.props.type]) {
      this.props.fetchPage(this.props.type);
    }
  }


  render() {
    let content: string = this.props.genericPages[this.props.type];

    if(content) {
      return (
        <div className='container mt-10'>
          <div className='htmlcontent'>{ReactHtmlParser(content)}</div>
        </div>
      );
    } else {
      return (
        <div className='container mt-10'>
          <div className='spinner'></div>
        </div>
      )
    }
  }
}

const mapStateToProps = (state: PortalState, ownProps: GenericPageOwnProps): GenericPageStateProps => {
  return {
    genericPages: state.genericPages,
  };
};

const dispatchProps = {
  fetchPage,
};

export default connect(mapStateToProps, dispatchProps)(GenericPage);
