import React from 'react';
import logo from '../images/logo.png';
import AddCommentIcon from '@material-ui/icons/AddComment';
import { PortalState, UserEntity } from '../reducers/states';
import { connect } from 'react-redux';


interface FooterProps {
  loggedInUser: UserEntity;
}


class Footer extends React.Component<FooterProps> {

  render() {
    const improveClaraLink = `https://digital-ant.typeform.com/to/Bd4x6W?clara=${this.props.loggedInUser.username}`;

    return (
      <div className='footer container mb-10 mt-40 px-6 flex justify-between items-end flex-wrap'>
        <div className='flex flex-col sm:my-1 md:my-2 my-5'>
          <a href='mailto:clara@happinessatwork.cz' className='mail'>
            clara@happinessatwork.cz
          </a>
          <a href='tel:+420724149039' className=''>
            +420 724 149 039
          </a>
        </div>
        <div className='logo sm:my-1 md:my-2 my-5'>
          <span>Claru vám přináší</span>
          <div>
            <a href='https://www.stestivpraci.cz/' target='_blank' rel='noopener noreferrer'>
              <img src={logo}></img>
            </a>
          </div>
        </div>
        <a href={improveClaraLink} target='_blank' rel='noopener noreferrer' className='feedback sm:my-1 md:my-2 my-5'>
          <AddCommentIcon /> <span>Vylepšete s námi Claru</span>
        </a>
      </div>
    );
  }
}

const mapStateToProps = (state: PortalState): FooterProps => {
  return {
    loggedInUser: state.common.loggedInUser,
  };
};

export default connect(mapStateToProps)(Footer);
