import React, { ChangeEvent } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { PortalState } from '../reducers/states';
import { logIn, logOut } from '../actions';
import { stringLiteralTypeAnnotation } from '@babel/types';
import { string } from 'prop-types';
import logoIcon from '../images/clara.png';
import { LoginResponse } from '../entities/api/login-response';


interface LoginDispatchProps {
  logIn(username: string, password: string): any;
  logOut(redirect: boolean): any;
}

interface LoginStateProps {
  loggingResponse: LoginResponse;
  isLoggingIn: boolean;
}

interface LoginState {
  username: string;
  password: string;
  submitted: boolean;
  attempts: number;
}

export class Login extends React.Component<LoginDispatchProps & LoginStateProps, LoginState> {

    private wasLoggingIn = false;

    constructor(props: LoginDispatchProps & LoginStateProps) {
        super(props);

        // reset login status
        this.props.logOut(false);

        this.state = {
            username: '',
            password: '',
            submitted: false,
            attempts: 0
        };
    }


    handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        const { name, value } = (e as any).target as HTMLInputElement;
        this.setState({ [name]: value } as any);
    }

    handleSubmit = (e: any) => {
        e.preventDefault();

        this.setState({ submitted: true, attempts: this.state.attempts+1 });
        const { username, password } = this.state;
        if (username && password) {
            this.props.logIn(username, password);
        }
    }

    render() {
      let isError = this.wasLoggingIn && !this.props.isLoggingIn && (this.props.loggingResponse !== LoginResponse.SUCCESS);
      this.wasLoggingIn = this.props.isLoggingIn === true;
      const response = this.props.loggingResponse;
        const { isLoggingIn } = this.props;
        const { username, password, submitted } = this.state;
        return (
            <div className='flex w-screen h-screen pb-10'>
            <div className='login w-full max-w-sm m-auto p-2'>
                <div className='text-center'>
                  <img className='image' src={logoIcon}></img>
                </div>
                <div className='logintitle mb-2 md:text-4xl text-4xl'>
                  Přihlášení do Clary
                </div>
                <form className='w-full max-w-sm' name='form' onSubmit={this.handleSubmit}>
                <div className='text-red-500 mb-2'>
                  {isError && ((response === LoginResponse.WRONG_CREDENTIALS && 'Špatné uživatelské jméno nebo heslo')) || (response === LoginResponse.EXPIRED && 'Váš účet expiroval.')}
                </div>
                    <div className={'mb-4'}>
                        <label className='block text-gray-700 text-sm mb-2' htmlFor='username'>Uživatelské jméno</label>
                        <input type='text' className={'appearance-none border w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:bg-white ' + (submitted && !username ? 'border-red-500' : 'focus:border-yellow-500')} name='username' value={username} onChange={this.handleChange} />
                        {submitted && !username &&
                            <div className='text-red-500'>Je potřeba uživatelské jméno</div>
                        }
                    </div>
                    <div className={'mb-4' + (submitted && !password ? ' border-red-500 ' : '')}>
                        <label className='block text-gray-700 text-sm mb-2' htmlFor='password'>Heslo</label>
                        <input type='password' className={'appearance-none border w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:bg-white ' + (submitted && !password ? 'border-red-500' : 'focus:border-yellow-500')} name='password' value={password} onChange={this.handleChange} />
                        {submitted && !password &&
                            <div className='text-red-500'>Je potřeba heslo</div>
                        }
                        {isError && this.state.attempts >= 3 &&
                            <div className='text-red-500'>Zapomenuté heslo? Napište na  <a href='mailto:clara@happinessatwork.cz' className='mail'>
                            clara@happinessatwork.cz
                          </a></div>
                        }
                    </div>
                    <div>
                        <button className='button-mid loginsubmit mt-2 w-full'>{(isLoggingIn) &&
                            <img className='mr-2' src='data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==' />
                        }
                        <span>Přihlásit</span></button>
                    </div>
                    <div className='interestedInClara mt-5 text-md'>
                      <a href='http://happinessatwork.cz/clara' target='_blank' rel='noopener noreferrer'>
                        Chcete se o Claře dozvědět víc?
                      </a>
                 </div>
                </form>
              </div>
            </div>
        );
    }
}

const mapStateToProps = (state: PortalState): LoginStateProps => {
  return {
    loggingResponse: state.common.loggingResponse,
    isLoggingIn: state.common.isLoggingIn
  };
};

const dispatchProps = {
  logIn,
  logOut,
};

export default connect(mapStateToProps, dispatchProps)(Login);