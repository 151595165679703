
export interface Enumerator {
  nameCz: string;
  name: string;
  valuesCz: string[];
  values: string[];
}

export enum TipTypes {
  PRACTICAL_TIPS,
  TOOLS
}

export enum GenericPageType {
  HOW_TO_BEGIN = 'HOW_TO_BEGIN',
  WHAT_ARE_PRINCIPLES = 'WHAT_ARE_PRINCIPLES'
}

export interface Label {
  name: string;
  description: string;
}

export interface Enumerators {
  enums: Enumerator[];
  labels: Label[];
}