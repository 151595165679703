import { Enumerator } from './entities/local/enumerator';
import { FilterState } from './reducers/states';

export const dateFormatter = (date: Date) => {
  let datestring = ('0' + date.getDate()).slice(-2) + '.' + ('0'+(date.getMonth()+1)).slice(-2) + '.' +
  date.getFullYear();
  return datestring;
};

export const splitMultilineTooltip = (tooltip: string) => {
  let output = '';
  let lastBreakIndex = 0;
  let pointer = 0;
  const breakCounter = 30;

  for(let char of tooltip) {
    if((pointer++ - lastBreakIndex) > breakCounter && char === ' ') {
      lastBreakIndex = pointer - 1;
      output += '<br>';
    } else {
      output += char;
    }
  }
  return output;
}

export const generateFilterQueryString = (state: FilterState): string => {
  let keys = [...(state.filters.keys() as any)];
  let builder = '';
  for(let key of keys) {
    let values = state.filters.get(key) as Set<string>;
    if(values.size !== 0) {
      if(builder === '') {
        builder = '?' + key + '=';
      } else {
        builder += '&' + key + '=';
      }
      builder += [...(values as any)].join('|');
    }
  }

  if(state.fullText.length !== 0) {
    if(builder === '') {
      builder = '?text=' + state.fullText;
    } else {
      builder += '&text=' + state.fullText;
    }
  }
  return builder;
};

export const getFilterFromQueryString = (query: string): (FilterState | undefined) => {
  if(query.startsWith('?')) {
    query = query.substring(query.indexOf('?') + 1);
    const queries = query.split('&');
    const state: FilterState = { fullText: '', filters: new Map()};
    for(let filter of queries) {
      const parsedFilter = filter.split('=');
      if(parsedFilter.length === 2) {
        const name = parsedFilter[0];
        if(name === 'text') {
          state.fullText = parsedFilter[1];
        } else {
          const value = parsedFilter[1].includes('|') ? new Set(parsedFilter[1].split('|')) : new Set([parsedFilter[1]]);
          state.filters.set(name, value);
        }
      }
    }
    return state;
  } else {
    return undefined;
  }
};

export const getFilteredValue = (state: FilterState, filterName: string, options: any[]) => {
  const filteredValues = (state.filters.get(filterName));
  if(filteredValues && filteredValues.size !== 0) {
    const value = [...(filteredValues as any)][0];

    return options.filter(opt => opt.value === value)[0];
  } else {
    return null;
  }
};

export const getEnumValue = (enums: Enumerator[], fieldName: string, fieldValue: string) => {
  try {
  const enumPart = enums.filter(en => en.name === fieldName)[0];
  return enumPart.valuesCz[enumPart.values.indexOf(fieldValue)];
  } catch(err) {
    console.error(err);
    return 'Neznámý';
  }
};

export const saltStr = (str: string) => {
  return str + 'e.Py2Bs5aN@';
};

export const sha1 = (str: string) => {
  //  discuss at: http://phpjs.org/functions/sha1/
  // original by: Webtoolkit.info (http://www.webtoolkit.info/)
  // improved by: Michael White (http://getsprink.com)
  // improved by: Kevin van Zonneveld (http://kevin.vanzonneveld.net)
  //    input by: Brett Zamir (http://brett-zamir.me)
  //  depends on: utf8_encode
  //   example 1: sha1('Kevin van Zonneveld');
  //   returns 1: '54916d2e62f65b3afa6e192e6a601cdbe5cb5897'

  const rotateLeft = (n: number, s: number) => {
    let t4 = (n << s) | (n >>> (32 - s));
    return t4;
  };

  /*var lsb_hex = function (val) { // Not in use; needed?
    var str="";
    var i;
    var vh;
    var vl;

    for ( i=0; i<=6; i+=2 ) {
      vh = (val>>>(i*4+4))&0x0f;
      vl = (val>>>(i*4))&0x0f;
      str += vh.toString(16) + vl.toString(16);
    }
    return str;
  };*/

  const cvtHex = (val: number) => {
    let str = '';
    let i: number;
    let v: number;

    for (i = 7; i >= 0; i--) {
      v = (val >>> (i * 4)) & 0x0f;
      str += v.toString(16);
    }
    return str;
  };

  let blockstart: number;
  let i: number, j: number;
  let W = new Array(80);
  let H0 = 0x67452301;
  let H1 = 0xEFCDAB89;
  let H2 = 0x98BADCFE;
  let H3 = 0x10325476;
  let H4 = 0xC3D2E1F0;
  let A, B, C, D, E;
  let temp;

  let strLen = str.length;

  let wordArray: number[] = [];
  for (i = 0; i < strLen - 3; i += 4) {
    j = str.charCodeAt(i) << 24 | str.charCodeAt(i + 1) << 16 | str.charCodeAt(i + 2) << 8 | str.charCodeAt(i + 3);
    wordArray.push(j);
  }

  switch (strLen % 4) {
    case 0:
      i = 0x080000000;
      break;
    case 1:
      i = str.charCodeAt(strLen - 1) << 24 | 0x0800000;
      break;
    case 2:
      i = str.charCodeAt(strLen - 2) << 24 | str.charCodeAt(strLen - 1) << 16 | 0x08000;
      break;
    case 3:
      i = str.charCodeAt(strLen - 3) << 24 | str.charCodeAt(strLen - 2) << 16 | str.charCodeAt(strLen - 1) <<
        8 | 0x80;
      break;
  }

  wordArray.push(i);

  while ((wordArray.length % 16) != 14) {
    wordArray.push(0);
  }

  wordArray.push(strLen >>> 29);
  wordArray.push((strLen << 3) & 0x0ffffffff);

  for (blockstart = 0; blockstart < wordArray.length; blockstart += 16) {
    for (i = 0; i < 16; i++) {
      W[i] = wordArray[blockstart + i];
    }
    for (i = 16; i <= 79; i++) {
      W[i] = rotateLeft(W[i - 3] ^ W[i - 8] ^ W[i - 14] ^ W[i - 16], 1);
    }

    A = H0;
    B = H1;
    C = H2;
    D = H3;
    E = H4;

    for (i = 0; i <= 19; i++) {
      temp = (rotateLeft(A, 5) + ((B & C) | (~B & D)) + E + W[i] + 0x5A827999) & 0x0ffffffff;
      E = D;
      D = C;
      C = rotateLeft(B, 30);
      B = A;
      A = temp;
    }

    for (i = 20; i <= 39; i++) {
      temp = (rotateLeft(A, 5) + (B ^ C ^ D) + E + W[i] + 0x6ED9EBA1) & 0x0ffffffff;
      E = D;
      D = C;
      C = rotateLeft(B, 30);
      B = A;
      A = temp;
    }

    for (i = 40; i <= 59; i++) {
      temp = (rotateLeft(A, 5) + ((B & C) | (B & D) | (C & D)) + E + W[i] + 0x8F1BBCDC) & 0x0ffffffff;
      E = D;
      D = C;
      C = rotateLeft(B, 30);
      B = A;
      A = temp;
    }

    for (i = 60; i <= 79; i++) {
      temp = (rotateLeft(A, 5) + (B ^ C ^ D) + E + W[i] + 0xCA62C1D6) & 0x0ffffffff;
      E = D;
      D = C;
      C = rotateLeft(B, 30);
      B = A;
      A = temp;
    }

    H0 = (H0 + A) & 0x0ffffffff;
    H1 = (H1 + B) & 0x0ffffffff;
    H2 = (H2 + C) & 0x0ffffffff;
    H3 = (H3 + D) & 0x0ffffffff;
    H4 = (H4 + E) & 0x0ffffffff;
  }

  temp = cvtHex(H0) + cvtHex(H1) + cvtHex(H2) + cvtHex(H3) + cvtHex(H4);
  return temp.toLowerCase();
}