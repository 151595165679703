export interface Analytics {
  Name: string;
  type: string;
  hodnota1: string;
  hodnota2: string;
}

export enum AnalyticsType {
  DETAIL = 'proklik',
  FULLTEXT_SEARCH = 'fulltext',
  FILTER = 'filtr'
}