import { FETCH_GENERIC_PAGE } from '../actions/types';

const defaultState: { [key: string]: string } = { };

export default (state = defaultState, action: any): { [key: string]: string } => {

  switch (action.type) {
    case FETCH_GENERIC_PAGE:
      return  {
        ...state,
        [action.payload[0]]: action.payload[1]
      };
    default:
      return state;
  }
};
