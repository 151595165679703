import {
  sendGlobalFeedbackAction, sendTipFeedbackAction, applyFilterAction, fetchTipDetailAction, fetchTipsAction, loginAction, logoutAction, loadAppAction, redirectAction,
  FetchTipsAction, FetchTipDetailAction, SendGlobalFeedbackAction, SendTipFeedbackAction, ApplyFilterAction, LoginAction, LogoutAction, LoadAppAction, RedirectAction,
  fetchTipsStartAction, FetchTipsStartAction
} from './types';
import axios from 'axios';
import { TipsResponse, TipRecord } from '../entities/api/tips';
import { tipMapper, tipsMapper } from '../mappers/tip-mapper';
import { FilterState, UserEntity } from '../reducers/states';
import { PracticalTip } from '../entities/local/tips';
import { RequestItem, Request } from '../entities/api/request';
import { FeedbackResponse } from '../entities/api/feedback';
import { Analytics, AnalyticsType } from '../entities/api/analytics';
import { sendAnalyticsAction, SendAnalyticsAction, LOGIN_BEGIN, CHANGE_PASSWORD_BEGIN, fetchEnumsAction, FetchEnumsAction, ChangePasswordAction, changePasswordAction, refreshTipsAction, RefreshTipsAction, setScrollingOffsetAction, SetOffsetAction, changeLoggedInUserAction, LoadGenericPageAction, loadGenericPageAction } from './types';
import { serializeFilter } from '../mappers/filter-serializer';
import { enumeratorsMapper } from '../mappers/enumerators';
import { EnumeratorsResponse } from '../entities/api/enumerators';
import { sha1, saltStr, generateFilterQueryString } from '../utils';
import { history } from '../store';
import { TipTypes, Enumerators, GenericPageType } from '../entities/local/enumerator';
import { LoginResponse } from '../entities/api/login-response';

type dispatchFunc<T> = (resp: T) => void;

const initAxios = (user?: UserEntity) => {
  return axios.create({
    baseURL: 'https://clara.happinessatwork.cz/backend.php',
    //baseURL: 'http://localhost:80/backend.php',
    headers: {
      common: {
        'Authorization': user ? user.token : ''
      }
    }
  });
};

let api = initAxios();

export const applyFilter = (newFilter: FilterState, updateLocation: boolean) => (dispatch: dispatchFunc<ApplyFilterAction>) => {
  // store into storage
  //localStorage.setItem('filters', serializeFilter(newFilter));
  // update url
  if (updateLocation) {
    history.push({
      search: generateFilterQueryString(newFilter)
    });
  }
  dispatch(applyFilterAction(newFilter));
};


export const loadApp = (isLoggedIn: boolean, filters?: FilterState) => async (dispatch: dispatchFunc<LoadAppAction>) => {
  dispatch(loadAppAction(isLoggedIn));
  if (filters) {
    dispatch(applyFilterAction(filters) as any);
  }
};

export const fetchPage = (type: GenericPageType) => async (dispatch: dispatchFunc<LoadGenericPageAction>) => {
  let urlBase = window.location.href;
  if(urlBase.endsWith('/')) {
    urlBase = urlBase.substr(0, urlBase.length - 1);
  }
  urlBase = urlBase.substr(0, urlBase.lastIndexOf('/'));
  
  let url: string;

  switch(type) {
    case GenericPageType.HOW_TO_BEGIN:
      url = '/assets/how_to_begin.html';
      break;
    case GenericPageType.WHAT_ARE_PRINCIPLES:
      url = '/assets/what_are_principles.html';  
    break;
  }

  if (url) {
    const response = await fetch(url);
    const text = await response.text();
    dispatch(loadGenericPageAction([type, text]));
  }
  
}

export const setScrollingOffset = (val: number) => async (dispatch: dispatchFunc<SetOffsetAction>) => {
  dispatch(setScrollingOffsetAction(val))
}

export const redirect = () => async (dispatch: dispatchFunc<RedirectAction>) => {
  dispatch(redirectAction());
};

export const logOut = (redirect: boolean) => async (dispatch: dispatchFunc<LogoutAction>) => {
  localStorage.removeItem('user')
  await api.post('/?api=logout');
  dispatch(logoutAction(redirect));
};

export const setLoggedInUser = (user: UserEntity) => async (dispatch: dispatchFunc<any>) => {
  api = initAxios(user);
  dispatch(changeLoggedInUserAction(user));
}

export const logIn = (username: string, password: string) => async (dispatch: dispatchFunc<any>) => {
  try {
    dispatch({ type: LOGIN_BEGIN } as any);

    const hash = sha1(saltStr(password));
    const response = await api.post('/?api=login', {
      username,
      password: hash
    });
    let user= response.data as UserEntity;
    localStorage.setItem('user', JSON.stringify(user));
    api = initAxios(user);
    dispatch(changeLoggedInUserAction(user));
    dispatch(loginAction(LoginResponse.SUCCESS));
  } catch (err) {
    // todo handle other codes than 401
    if(err.response && err.response.data && err.response.data.trim() === 'EXPIRED') {
      dispatch(loginAction(LoginResponse.EXPIRED));
    } else {
      dispatch(loginAction(LoginResponse.WRONG_CREDENTIALS));
    }
    return;
  }
};

export const changePassword = (oldPassword: string, newPassword: string) => async (dispatch: dispatchFunc<ChangePasswordAction>) => {
  try {
    dispatch({ type: CHANGE_PASSWORD_BEGIN } as any);

    const response = await api.post('/?api=change_password', {
      oldPassword: sha1(saltStr(oldPassword)),
      newPassword: sha1(saltStr(newPassword)),
    });
    let token = response.data as any;
    const currentUser = JSON.parse(localStorage.getItem('user') || '') as UserEntity;
    currentUser.token = token.token;
    localStorage.setItem('user', JSON.stringify(currentUser));
    api = initAxios(currentUser);
    dispatch(changePasswordAction(true));
  } catch (err) {
    // todo handle other codes than 401
    dispatch(changePasswordAction(false));
    return;
  }
};

const fetchEnumsOnly = async () => {
  const response = await api.get('/?api=enumerators');
  let enumsResponse = response.data as EnumeratorsResponse;
  let mappedObject = enumeratorsMapper(enumsResponse);
  return mappedObject;
};

export const fetchEnums = () => async (dispatch: dispatchFunc<FetchEnumsAction>) => {
  const enums = await fetchEnumsOnly();
  dispatch(fetchEnumsAction(enums));
};

export const refreshTips = () => async (dispatch: dispatchFunc<RefreshTipsAction>) => {
  dispatch(refreshTipsAction(true));
};

export const fetchTips = (tipType: TipTypes, enums: Enumerators) => async (dispatch: dispatchFunc<FetchTipsAction>) => {
  dispatch(fetchTipsStartAction(true) as any);
  if(enums.enums.length === 0) {
    enums = await fetchEnumsOnly();
    dispatch(fetchEnumsAction(enums) as any);
  }

  try {
    let url: string;
    switch (tipType) {
      case TipTypes.PRACTICAL_TIPS:
        url = '/?api=tips';
        break;
      case TipTypes.TOOLS:
        url = '/?api=tools';
        break;
      default:
        url = '/?api=tips';
        break;
    }
    const response = await api.get(url);
    let tipsResponse = response.data as TipsResponse;
    let mappedObject = tipsMapper(tipsResponse, enums.enums, tipType);
    dispatch(fetchTipsAction(mappedObject));
  } catch (err) {
    if (err.response && err.response.status === 401) {
      dispatch(logoutAction(true) as any);
    } else {
      throw err;
    }
  }
};

export const fetchTipDetail = (id: string, allTips: PracticalTip[], tipType: TipTypes) => async (dispatch: dispatchFunc<FetchTipDetailAction>) => {
  if (allTips) {
    // take it from here
    let detail = allTips.filter(tp => tp.id === id);
    if (detail.length !== 0) {
      dispatch(fetchTipDetailAction(detail[0]));
      return;
    }
  }
  try {
    const enums = await fetchEnumsOnly();
    let url: string;
    switch (tipType) {
      case TipTypes.PRACTICAL_TIPS:
        url = `/?api=tipdetail&id=${id}`
        break;
      case TipTypes.TOOLS:
        url = `/?api=tooldetail&id=${id}`
        break;
      default:
        url = `/?api=tipdetail&id=${id}`
        break;
    }
    const response = await api.get(url);
    let tipResponse = response.data as TipRecord;
    let mappedObject = tipMapper(tipResponse, enums.enums, tipType);
    dispatch(fetchTipDetailAction(mappedObject));
  } catch (err) {
    if (err.response && err.response.status === 401) {
      dispatch(logoutAction(true) as any);
    } else {
      throw err;
    }
  }
};

export const createGlobalFeedback = (text: string) => async (dispatch: dispatchFunc<SendGlobalFeedbackAction>) => {
  const reqItem: RequestItem<FeedbackResponse> = {
    fields: {
      user: 'test',
      name: '',
      response: text,
    }
  };
  const reqRecord: Request<FeedbackResponse> = {
    records: [reqItem]
  };
  try {
    const response = await api.post(`/?api=feedback`, reqRecord);
    dispatch(sendGlobalFeedbackAction(true));
  } catch (err) {
    if (err.response && err.response.status === 401) {
      dispatch(logoutAction(true) as any);
    } else {
      throw err;
    }
  }
};

export const createTipFeedback = (tipName: string, text: string) => async (dispatch: dispatchFunc<SendTipFeedbackAction>) => {
  const reqItem: RequestItem<FeedbackResponse> = {
    fields: {
      user: 'test',
      name: tipName,
      response: text,
    }
  };
  const reqRecord: Request<FeedbackResponse> = {
    records: [reqItem]
  };

  try {
    const response = await api.post(`?api=feedback`, reqRecord);
    dispatch(sendTipFeedbackAction(true));
  } catch (err) {
    if (err.response && err.response.status === 401) {
      dispatch(logoutAction(true) as any);
    } else {
      throw err;
    }
  }
};


export const analyticsDetail = (name: string) => async (dispatch: dispatchFunc<SendAnalyticsAction>) => {
  const reqItem: RequestItem<Analytics> = {
    fields: {
      Name: name,
      type: AnalyticsType.DETAIL,
      hodnota1: '',
      hodnota2: '',
    }
  };
  const reqRecord: Request<Analytics> = {
    records: [reqItem]
  };

  try {
    const response = await api.post(`/?api=analytics`, reqRecord);
    dispatch(sendAnalyticsAction(true));
  } catch (err) {
    if (err.response && err.response.status === 401) {
      dispatch(logoutAction(true) as any);
    } else {
      throw err;
    }
  }
};

export const analyticsFulltext = (name: string) => async (dispatch: dispatchFunc<SendAnalyticsAction>) => {
  const reqItem: RequestItem<Analytics> = {
    fields: {
      Name: name,
      type: AnalyticsType.FULLTEXT_SEARCH,
      hodnota1: '',
      hodnota2: '',
    }
  };
  const reqRecord: Request<Analytics> = {
    records: [reqItem]
  };

  try {
    const response = await api.post(`/?api=analytics`, reqRecord);
    dispatch(sendAnalyticsAction(true));
  } catch (err) {
    if (err.response && err.response.status === 401) {
      dispatch(logoutAction(true) as any);
    } else {
      throw err;
    }
  }
};

export const analyticsFilter = (category: string, value: string, enabled: boolean) => async (dispatch: dispatchFunc<SendAnalyticsAction>) => {
  const reqItem: RequestItem<Analytics> = {
    fields: {
      Name: category,
      type: AnalyticsType.FILTER,
      hodnota1: value,
      hodnota2: enabled ? 'zapnuto' : 'vypnuto',
    }
  };
  const reqRecord: Request<Analytics> = {
    records: [reqItem]
  };

  try {
    const response = await api.post(`/?api=analytics`, reqRecord);
    dispatch(sendAnalyticsAction(true));
  } catch (err) {
    if (err.response && err.response.status === 401) {
      dispatch(logoutAction(true) as any);
    } else {
      throw err;
    }
  }
};