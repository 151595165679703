import React, { ReactElement } from 'react';
import { createGlobalFeedback, createTipFeedback } from '../actions/index';
import { connect } from 'react-redux';
import tipGlobal from '../images/tip_global.png';
import tipFeedback from '../images/tip_feedback.png';

interface FeedbackDispatchProps {
  createGlobalFeedback(text: string): any;
  createTipFeedback(tipName: string, text: string): any;
}

export interface FeedbackFormProps {
  content?: string;
}

interface FeedbackProps {
  tipName?: string;
  onSubmit(): void;
}

class Feedback extends React.Component<FeedbackFormProps & FeedbackDispatchProps & FeedbackProps, FeedbackFormProps> {

  constructor(props: any) {
    super(props);
    this.state = {
      content: ''
    };
  }



  handleSubmit = (evt: any) => {
    evt.preventDefault();
    if(this.props.tipName) {
      this.props.createTipFeedback(this.props.tipName, this.state.content as string);
    } else {
      this.props.createGlobalFeedback(this.state.content as string);
    }
    this.props.onSubmit();
  }

  onTextChange = (evt: React.FormEvent<HTMLTextAreaElement>) => {
    this.setState({
      content: (evt.target as any).value
    });
  }

  render() {
    return (
      <form className='p-4'
        onSubmit={this.handleSubmit}
      >
        {this.props.tipName && this.renderTip()}
        {!this.props.tipName && this.renderGlobal()}
      </form>
    );
  }

  renderGlobal() {
    return (
      <div className='flex'>
        <div className='flex-1 mx-2'>
          <img className='feedbackImg' src={tipGlobal}></img>
        </div>
        <div className='flex-2 mx-2 feedback'>
          <h2>Napište Claře</h2>
          <p>Co se Vám líbí, co byste potřebovali vylepšit?</p>
        <div>
          <textarea name='content' className='w-full h-200 border-solid border border-gray-600' autoComplete='off' onChange={this.onTextChange}>{this.state.content}</textarea>
        </div>
          <button className='button-mid float-right'>Poslat</button>
        </div>
      </div>
    );
  }

  renderTip() {
    return (
      <div className='flex'>
        <div className='flex-1 mx-2'>
          <img className='feedbackImg' src={tipFeedback}></img>
        </div>
        <div className='flex-2 mx-2 feedback'>
          <h2>Jak Vám tip pomohl?</h2>
          <p>Co byste doporučili těm, kteří ho chtějí vyzkoušet?</p>
        <div>
          <textarea name='content' className='w-full h-200 border-solid border border-gray-600' autoComplete='off' onChange={this.onTextChange}>{this.state.content}</textarea>
        </div>
          <button className='button-mid float-right'>Poslat</button>
        </div>
      </div>
    );
  }
}

const dispatchProps = {
  createGlobalFeedback,
  createTipFeedback
};

export default connect(null, dispatchProps)(Feedback);
