import React, { ChangeEvent } from 'react';
import { connect } from 'react-redux';
import { PortalState } from '../reducers/states';
import { changePassword } from '../actions';
import logoIcon from '../images/clara.png';


interface ChangePasswordDispatchProps {
  changePassword(oldPassword: string, newPassword: string): any;
}

interface ChangePasswordStateProps {
  changed: boolean;
  isChanging: boolean;
}

interface ChangePasswordState {
  oldPassword: string;
  newPassword: string;
  newPassword2: string;
  submitted: boolean;
  passwordsNotSame: boolean;
  passwordTooShort: boolean;
}

export class Login extends React.Component<ChangePasswordDispatchProps & ChangePasswordStateProps, ChangePasswordState> {

    private wasChanging = false;

    constructor(props: ChangePasswordDispatchProps & ChangePasswordStateProps) {
        super(props);

        this.state = {
            oldPassword: '',
            newPassword: '',
            newPassword2: '',
            submitted: false,
            passwordsNotSame: false,
            passwordTooShort: false,
        };
    }


    handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        const { name, value } = (e as any).target as HTMLInputElement;
        this.setState({ [name]: value } as any);
    }

    handleSubmit = (e: any) => {
        e.preventDefault();
        const { oldPassword, newPassword, newPassword2 } = this.state;
        this.setState({ submitted: true, passwordsNotSame: false, passwordTooShort: false });
        if (oldPassword && newPassword && newPassword2) {
          if(newPassword !== newPassword2) {
            this.setState({ passwordsNotSame: true });
          } else if(newPassword.length < 8) {
            this.setState({ passwordTooShort: true });
          } else {
            this.props.changePassword(oldPassword, newPassword);
          }
        }
    }

    goBack() {
      (this.props as any).history.goBack();
    }

    render() {
      let isError = this.wasChanging && !this.props.isChanging && !this.props.changed;
      this.wasChanging = this.props.isChanging === true;
        const { isChanging } = this.props;
        const { oldPassword, newPassword, newPassword2, submitted, passwordsNotSame, passwordTooShort } = this.state;
        return (
            <div className='flex w-screen h-screen pb-10'>
            <div className='login w-full max-w-sm m-auto'>
                <div className='text-center'>
                  <img className='image' src={logoIcon}></img>
                </div>
                <div className='logintitle mb-2'>
                  Změna hesla
                </div>
                <form className='w-full max-w-sm' name='form' onSubmit={this.handleSubmit}>
                    <div className={'mb-4' + (submitted && !oldPassword ? ' border-red-500 ' : '')}>
                        <label className='block text-gray-700 text-sm mb-2' htmlFor='oldPassword'>Staré heslo</label>
                        <input type='password' className={'appearance-none border w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:bg-white ' + (submitted && !oldPassword ? 'border-red-500' : 'focus:border-yellow-500')} name='oldPassword' value={oldPassword} onChange={this.handleChange} />
                        {submitted && !oldPassword &&
                            <div className='text-red-500'>Je potřeba heslo</div>
                        }
                        {isError && 
                          <div className='text-red-500 mb-2'>
                            Špatné heslo
                          </div>
                        }
                    </div>
                    <div className={'mb-4' + (submitted && !newPassword ? ' border-red-500 ' : '')}>
                        <label className='block text-gray-700 text-sm mb-2' htmlFor='newPassword'>Nové heslo</label>
                        <input type='password' className={'appearance-none border w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:bg-white ' + (submitted && !newPassword ? 'border-red-500' : 'focus:border-yellow-500')} name='newPassword' value={newPassword} onChange={this.handleChange} />
                        {submitted && !newPassword &&
                            <div className='text-red-500'>Je potřeba heslo</div>
                        }
                        {passwordTooShort &&
                            <div className='text-red-500'>Nové heslo musí mít alespoň 8 znaků</div>
                        }
                    </div>
                    <div className={'mb-4' + (submitted && !newPassword2 ? ' border-red-500 ' : '')}>
                        <label className='block text-gray-700 text-sm mb-2' htmlFor='newPassword2'>Nové heslo znovu</label>
                        <input type='password' className={'appearance-none border w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:bg-white ' + (submitted && !newPassword2 ? 'border-red-500' : 'focus:border-yellow-500')} name='newPassword2' value={newPassword2} onChange={this.handleChange} />
                        {submitted && !newPassword2 &&
                            <div className='text-red-500'>Je potřeba heslo</div>
                        }
                        {passwordsNotSame &&
                            <div className='text-red-500'>Hesla nejsou stejná</div>
                        }
                    </div>
                    <div>
                        <button className='button-semi loginsubmit float-left'>{(isChanging) &&
                            <img className='mr-2' src='data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==' />
                        }
                        <span>Změnit heslo</span></button>
                        <button className='cancel-button float-right' onClick={() => this.goBack()}>Storno</button>
                    </div>
                </form>
            </div></div>
        );
    }
}

const mapStateToProps = (state: PortalState): ChangePasswordStateProps => {
  return {
    changed: state.common.passwordChanged,
    isChanging: state.common.isPasswordChanging
  };
};

const dispatchProps = {
  changePassword
};

export default connect(mapStateToProps, dispatchProps)(Login);