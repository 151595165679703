import { FetchTipDetailAction } from './../actions/types';
import { FETCH_TIPS, FETCH_TIPS_START, FETCH_TIP_DETAIL, SEND_TIP_FEEDBACK, SEND_GLOBAL_FEEDBACK, FetchTipsAction, FETCH_ENUMS, FetchEnumsAction, REFRESH_TIPS, SET_SCROLLING_OFFSET, LOGOUT } from '../actions/types';
import { AirtableState } from './states';

const defaultState = { tips: [], enums: { enums: [], labels: []}, refreshRequired: false, refreshingTips: false,   tipScrollingOffset: 0 };

export default (state: AirtableState = defaultState, action: any): AirtableState => {
  const modState = { ...state, refreshRequired: false, refreshingTips: false };
  switch (action.type) {
    case REFRESH_TIPS:
      return { ...modState, refreshRequired: true, tips: [] };
    case FETCH_TIPS_START:
        return {...modState, refreshingTips: true };
    case FETCH_TIPS:
      return { ...modState, refreshingTips: false, refreshRequired: false, tips: (action as FetchTipsAction).payload };
    case FETCH_ENUMS:
        return { ...modState, enums: (action as FetchEnumsAction).payload };
    case FETCH_TIP_DETAIL:
      return { ...modState, tipDetail: (action as FetchTipDetailAction).payload, refreshRequired: false };
    case SEND_TIP_FEEDBACK:
      return modState;
    case SEND_GLOBAL_FEEDBACK:
      return modState;
    case SET_SCROLLING_OFFSET:
      return { ...modState, tipScrollingOffset: action.payload}
    case LOGOUT:
      return defaultState; // reset data
    default:
      return state;
  }
};
